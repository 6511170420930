export const menus = [
  {
    title: "dashboard",
    icon: "demo-pli-home",
    url: { name: "Dashboard" },
    notifications: [],
  },
  {
    title: "orders",
    icon: "demo-pli-tag-3",
    url: { name: "Orders" },
    notifications: [],
  },
  {
    title: "menu_items",
    icon: "demo-pli-mine",
    url: { name: "Items" },
    notifications: [],
  },
  {
    title: "tables",
    icon: "demo-pli-receipt-4",
    url: { name: "Tables" },
    notifications: [],
  },
  {
    title: "branches",
    icon: "demo-pli-map-marker",
    url: { name: "Branches" },
    notifications: [],
  },
  {
    title: "reports",
    icon: "demo-pli-information",
    url: { name: "ReportsIndex" },
    notifications: [],
  },
  {
    title: "sales",
    icon: "demo-pli-shopping-cart",
    url: { name: "Sales" },
    notifications: [],
  },
  {
    title: "lead",
    icon: "demo-pli-male-2",
    url: { name: "" },
    notifications: [],
    submenus: {
      users: { name: "Users" },
      clients: { name: "Clients" },
    },
  },
  {
    title: "settings",
    icon: "demo-pli-gear",
    url: { name: "" },
    notifications: [],
    submenus: {
      general: { name: "" },
      user_roles: { name: "" },
      items_types: { name: "" },
      items_units: { name: "" },
    },
  },
];
