<template>
  <nav id="mainnav-container" class="mainnav">
    <div class="mainnav__inner">
      <div class="mainnav__top-content scrollable-content pb-5">
        <div class="text-end">
          <span class="fw-bolder" :class="{ 'text-danger': requiredEOD }">{{
            $helper.formatDate(today)
          }}</span>
        </div>
        <div
          class="mainnav__profile mt-0 d-flex justify-content-end"
          :class="{ 'text-danger': requiredEOD }"
        >
          <digital-clock />
        </div>
        <div class="mainnav__categoriy py-1">
          <h6 class="mainnav__caption mt-0 px-3 fw-bold">Navigation</h6>
          <ul class="mainnav__menu nav flex-column">
            <template v-for="menu in menus">
              <li
                class="nav-item"
                :key="menu.title"
                v-if="$helper.empty(menu.submenus)"
              >
                <router-link
                  :to="menu.url"
                  class="nav-link mininav-toggle collapsed"
                  :class="{ active: menu.url.name == currentRoute }"
                  ><i :class="`${menu.icon} fs-5 me-2`"></i>

                  <span
                    class="nav-label mininav-content ms-1 text-capitalize"
                    >{{ $helper.inputTitle(menu.title) }}</span
                  >
                </router-link>
              </li>
              <li class="nav-item has-sub" :key="menu.title" v-else>
                <a
                  @click.prevent="showSubMenus(menu.title)"
                  href="javascript:void(0)"
                  :id="menu.title"
                  class="nav-link mininav-toggle collapsed"
                  :class="{ active: menu.url.name == currentRoute }"
                  ><i :class="`${menu.icon} fs-5 me-2`"></i>

                  <span
                    class="nav-label mininav-content ms-1 text-capitalize"
                    >{{ menu.title }}</span
                  >
                </a>
                <ul class="mininav-content nav collapse">
                  <li
                    class="nav-item"
                    v-for="(item, i) in menu.submenus"
                    :key="i"
                  >
                    <router-link :to="item" class="nav-link"
                      ><span class="nk-menu-text text-capitalize">{{
                        $helper.inputTitle(i)
                      }}</span></router-link
                    >
                  </li>
                </ul>
              </li>
            </template>
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                class="nav-link mininav-toggle collapsed"
                @click.prevent="getBranches"
              >
                <svg
                  class="fs-5 me-2"
                  height="15px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                  ></path>
                  <path
                    d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
                  ></path>
                </svg>
                <span class="nav-label mininav-content ms-1 text-capitalize"
                  >Front Office</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="mainnav__bottom-content border-top pb-2">
        <ul id="mainnav" class="mainnav__menu nav flex-column">
          <li class="nav-item">
            <a
              class="nav-link mininav-toggle collapsed"
              @click.prevent="handleLogout"
              href="javascript:void(0)"
              aria-expanded="false"
            >
              <i class="demo-pli-unlock fs-5 me-2"></i>
              <span class="nav-label ms-1">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <bootstrap-modal v-if="modalOpen" @close="toggleModal" modal-size="modal-sm">
    <template #head>
      <span>Select Branch and Continue</span>
    </template>
    <template #body>
      <div class="w-100">
        <loading-dots v-if="!branches.length" />
        <template v-else>
          <div class="form-group mb-3">
            <select
              class="form-control-lg form-select"
              v-model="selectedBranch"
            >
              <option value="null" hidden disabled>Select Branch</option>
              <option
                :value="branch.id"
                v-for="branch in branches"
                :key="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              @click="handleContinue"
              :disabled="!selectedBranch"
            >
              Continue
            </button>
          </div>
        </template>
      </div>
    </template>
  </bootstrap-modal>
</template>
<script>
import DigitalClock from "@/components/DigitalClock.vue";
import BootstrapModal from "@/components/BootstrapModal.vue";
import LoadingDots from "@/components/LoadingDots.vue";
import { menus } from "./menus.js";
export default {
  name: "NavBar",
  components: {
    DigitalClock,
    BootstrapModal,
    LoadingDots,
  },
  data: () => ({
    menus,
    selectedBranch: null,
    branches: [],
    today: TODAY,
  }),
  computed: {
    currentRoute() {
      //return this.$route.fullPath
      return this.$route.name;
    },
    requiredEOD() {
      return this.$store.state.isCorrectSystemDay == false;
    },
  },
  methods: {
    handleContinue() {
      this.$store.commit("SET_LOCKED_BLANCH", this.selectedBranch);
      let href = this.$router.resolve({
        name: "OrdersCreator",
        params: { action: "new" },
      }).href;
      location.href = href;
    },
    getBranches() {
      this.toggleModal();
      if (!this.branches.length) {
        this.$store.commit("SET_REQUEST_FLAG", "GET_BRANCHES");
        this.$http.get("branches/show").then((response) => {
          this.branches = response.data.branches;
        });
      }
    },
    showSubMenus(id) {
      var elt = document.querySelector(`a#${id}`);
      var parent = elt.parentElement;
      elt.classList.toggle("collapsed");
      var ul = parent.querySelector("ul");
      [...document.querySelectorAll(".mininav-content.nav")].forEach((item) => {
        if (item != ul) {
          item.classList.remove("show");
          item.classList.add("collapsed");
        }
      });

      ul.classList.toggle("show");
      ul.classList.toggle("collapsed");
    },
  },
};
</script>
