<template>
  <header class="header">
    <div class="header__inner">
      <div class="header__brand">
        <div class="brand-wrap">
          <a href="javascript:void(0)" class="brand-img stretched-link">
            <img
              src="/img/logo-min.png"
              alt="TAME Logo"
              class="logo"
              width="40"
              height="40"
            />
          </a>
          <div class="brand-title">TAME</div>
        </div>
      </div>
      <div class="header__content">
        <div class="header__content-start" style="flex-grow: 0">
          <button
            type="button"
            class="nav-toggler header__btn btn btn-icon btn-sm"
          >
            <i class="demo-psi-view-list"></i>
          </button>
          <div class="header-searchbox" v-if="false">
            <label
              for="header-search-input"
              class="header__btn d-md-none btn btn-icon rounded-pill shadow-none border-0 btn-sm"
              type="button"
            >
              <i class="demo-psi-magnifi-glass"></i>
            </label>
            <form
              class="searchbox searchbox--auto-expand searchbox--hide-btn input-group"
            >
              <input
                id="header-search-input"
                class="searchbox__input form-control bg-transparent"
                type="search"
                placeholder="Type for search . . ."
              />
              <div class="searchbox__backdrop">
                <button
                  class="searchbox__btn header__btn btn btn-icon rounded shadow-none border-0 btn-sm"
                  type="button"
                  id="button-addon2"
                >
                  <i class="demo-pli-magnifi-glass"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="mx-auto">
          <div class="dropdown">
            <a
              href="javascript:void(0)"
              class="d-flex flex-nowrap align-items-center"
              data-bs-toggle="dropdown"
            >
              <img src="/img/restaurant.png" class="img-xs" alt="Branch Icon" />
              <span class="h5 ms-1 text-white mb-0">{{
                lockedBranch.name || "All Branches"
              }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end"
              data-popper-placement="bottom-end"
            >
              <div
                class="list-group list-group-borderless listgroup-search scrollable-content"
              >
                <div
                  v-if="!$helper.empty(lockedBranch)"
                  class="list-group-item list-group-item-action d-flex align-items-start mb-2"
                >
                  <a
                    href="javascript:void(0)"
                    @click.prevent="setBranch(null)"
                    class="h6 d-block mb-0 stretched-link text-decoration-none"
                    >All Branches</a
                  >
                </div>
                <div
                  v-for="branch in branches"
                  :key="branch.id"
                  class="list-group-item list-group-item-action d-flex align-items-start mb-2"
                >
                  <a
                    href="javascript:void(0)"
                    @click.prevent="setBranch(branch)"
                    class="h6 d-block mb-0 stretched-link text-decoration-none"
                    >{{ branch.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__content-end">
          <div class="dropdown">
            <button
              class="header__btn btn btn-icon btn-sm"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span class="d-block position-relative">
                <i class="demo-psi-bell"></i>
                <span class="badge badge-super rounded bg-danger p-1">
                  <span class="visually-hidden">unread messages</span>
                </span>
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end w-md-300px"
              data-popper-placement="bottom-end"
            >
              <div class="border-bottom px-3 py-2 mb-3">
                <h5>Notifications</h5>
              </div>

              <div class="list-group list-group-borderless">
                <div
                  class="list-group-item list-group-item-action d-flex align-items-start mb-3"
                >
                  <div class="flex-shrink-0 me-3">
                    <i class="demo-psi-data-settings text-muted fs-2"></i>
                  </div>
                  <div class="flex-grow-1">
                    <a
                      href="javascript:void(0)"
                      class="h6 d-block mb-0 stretched-link text-decoration-none"
                      >Your storage is full</a
                    >
                    <small class="text-muted"
                      >Local storage is nearly full.</small
                    >
                  </div>
                </div>

                <div class="text-center mb-2">
                  <a href="javascript:void(0)" class="btn-link"
                    >Show all Notifications</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <button
              class="header__btn btn btn-icon btn-sm"
              type="button"
              data-bs-toggle="dropdown"
            >
              <i
                class="demo-psi-gear fs-4"
                :class="{ 'notification-bell': !isCorrectSystemDay }"
              ></i>
            </button>

            <div
              class="dropdown-menu dropdown-menu-end w-md-250px"
              data-popper-placement="bottom-end"
              style=""
            >
              <div class="d-flex align-items-center border-bottom px-3 py-2">
                <div class="flex-shrink-0">
                  <avatar
                    :name="loggedUser.name"
                    width="2.25rem"
                    height="2.25rem"
                  ></avatar>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="mb-0">{{ loggedUser.name }}</h5>
                  <span class="text-muted fst-italic">{{
                    loggedUser.email
                  }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="list-group list-group-borderless h-100 py-3">
                    <router-link
                      :to="{ name: '' }"
                      class="list-group-item list-group-item-action"
                    >
                      <i class="demo-pli-male fs-5 me-2"></i> Profile
                    </router-link>
                    <router-link
                      :to="{ name: '' }"
                      class="list-group-item list-group-item-action"
                    >
                      <i class="demo-pli-gear fs-5 me-2"></i> Settings
                    </router-link>

                    <a
                      v-if="!isCorrectSystemDay"
                      href="javascript:void(0)"
                      @click.prevent="toggleModal"
                      class="list-group-item list-group-item-action mt-auto"
                    >
                      <i class="demo-pli-computer-secure fs-5 me-2"></i>
                      <span>Start a Day</span>
                      <span class="badge rounded bg-danger p-1 ms-1"
                        ><span class="visually-hidden"></span
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click.prevent="signout"
                      class="list-group-item list-group-item-action"
                    >
                      <i class="demo-pli-unlock fs-5 me-2"></i> Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <bootstrap-modal v-if="modalOpen" @close="toggleModal" modal-size="modal-sm">
    <template #head>
      <span>Start a new day</span>
    </template>
    <template #body>
      <div class="w-100">
        <loading-dots v-if="requestFlag == 'GET_LAST_EOD'" />
        <template v-else>
          <div class="form-group mb-2">
            <label for="from-date">System Date</label>
            <input
              type="date"
              class="form-control form-control-lg"
              v-model="newDay.from"
              id="from-date"
            />
          </div>
          <div class="form-group mb-2">
            <label for="to-date">Current Date</label>
            <input
              type="date"
              class="form-control form-control-lg"
              v-model="newDay.to"
              id="to-date"
            />
          </div>
          <div class="form-check">
            <input
              id="_dm-loginCheck"
              class="form-check-input"
              type="checkbox"
              v-model="confirmed"
            />
            <label for="_dm-loginCheck" class="form-check-label">
              I do Confirm
            </label>
          </div>
          <div class="form-group mt-2">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              @click="createNewDay"
              :disabled="!newDay.from || !newDay.to || !confirmed"
            >
              Confirm
            </button>
          </div>
        </template>
      </div>
    </template>
  </bootstrap-modal>
</template>
<script>
import Avatar from "@/components/Avatar.vue";
import BootstrapModal from "@/components/BootstrapModal.vue";
import { mapState } from "vuex";
import LoadingDots from "@/components/LoadingDots.vue";
export default {
  name: "AppHeader",
  components: {
    Avatar,
    BootstrapModal,
    LoadingDots,
  },
  data: () => ({
    userDropdown: false,
    notification: false,
    hasReadAll: false,
    confirmed: false,
    newDay: {
      from: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      to: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
    },
  }),
  computed: {
    ...mapState({
      lockedBranch: (state) => state.branch,
      requestFlag: (state) => state.requestFlag,
      isCorrectSystemDay: (state) => state.isCorrectSystemDay,
      branches: (state) => state.branches,
    }),
    notifications() {
      return this.loggedUser.alerts || [];
    },
    hasNotifications() {
      return (
        this.notifications.some((item) => item.read_at == null) &&
        !this.hasReadAll
      );
    },
  },
  created() {},
  methods: {
    showNotifications() {
      this.notification = !this.notification;
      if (this.hasNotifications) {
        this.$store.commit("SET_REQUEST_FLAG", "READING_NOTIFICATIONS");
        this.$http.get("auth/notifications/read")
          .then(() => {
            this.hasReadAll = true;
          });
      }
    },
    notificationIcon(event) {
      var result;
      switch (event) {
        case "StockTransfer":
          result = "bg-success ni-building-fill";
          break;
        case "RequisitionUpdated":
          result = "bg-primary-dim ni-reload";
          break;
        default:
          result = "bg-primary ni ni-trend-up";
      }
      return result;
    },
    signout() {
      this.$http
        .post("auth/logout", this.$helper.generateFormData({}))
        .then((response) => {
          if (response.data.status) {
            this.$store.dispatch("logout").then(() => {
              window.location.replace("/");
            });
          }
        });
    },
    createNewDay() {
      this.$http
        .post("settings/close-day", this.$helper.generateFormData(this.newDay))
        .then((response) => {
          if (response.data.status) {
            this.toggleModal();
            this.$store.state.isCorrectSystemDay = true;
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: "END OF DAY created successfully",
            });
          }
        });
    },
    setBranch(branch) {
      this.$store.commit("SET_LOCKED_BLANCH", branch);
      location.reload();
    },
  },
  mounted() {
    this.$store.commit("SET_REQUEST_FLAG", "GET_LAST_EOD");
    this.$http.get("settings/eod/latest")
      .then((response) => {
        var previousDay = new Date(response.data.yesterday);
        if (response.data.row) {
          previousDay = response.data.row.to_date;
        }
        if (TODAY == previousDay) {
          this.$store.state.isCorrectSystemDay = true;
        } else {
          this.newDay.from = new Date(previousDay)
            .toLocaleString("fr-CA", { timeZone: TIMEZONE })
            .slice(0, 10);
          this.toggleModal();
        }
      });
  },
};
</script>
